import React, { Component ,Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import '../App.scss';
import { connect } from "react-redux";
import { IS_USER_ACCESS_PAGE, IS_FAVOURITE_LOGIN ,IS_TRIIVCO_LOGIN} from '../config/constant';
import UCRFCommonService from '../services/refinance/CommonService';
import UBRFCommonService from '../services/bike-refinance/CommonService';
/** UCF ROUTES IMPORT STARTS HERE */
const Login = lazy(() => import("./../view/login/Login"));
const RemoveInfo = lazy(() => import("./../view/login/RemoveInfo.js"));
const Logout = lazy(() => import("../view/login/logout.js"));
const Header = lazy(() => import("../view/common/Header"));
const Footer = lazy(() => import("../view/common/Footer"));
const LeadList = lazy(() => import("../view/lead-list/LeadList"));
const LeadDetail = lazy(() => import("../view/lead-detail/LeadDetail"));
const RuleEngine = lazy(() => import("../view/rule-engine/RuleEngine"));
const Dashboard = lazy(() => import("../view/dashboard/Dashboard"));
const ManageRole = lazy(() => import("../view/manage-role/component/ManageRoleTab"));
const Financier = lazy(() => import("../view/financier/Financier"));
const FinancerBranch = lazy(() => import("../view/financier/FinancerBranch"));
const Mmvmaster = lazy(() => import("./../view/rule-engine/component/mmv-info/Mmvmaster"));
const DeviationConfig = lazy(() => import("../view/deviation/DeviationConfig"));
const AccessDenied = lazy(() => import("../view/access-denied/AccessDenied"));
const TATReport = lazy(() => import("../view/tat-report/TATReport"));
const OpseeDealerConfig = lazy(() => import("../view/opsee-dealer-config/OpseeDealerConfig.js"));
const RunnerMappingList = lazy(() => import("../view/opsee-dealer-config/RunnerList.js"));

/** UCRF ROUTES IMPORT STARTS HERE */
const RefinanceLeadDetail = lazy(() => import("./../view/refinance/lead_details/component/LeadDetail"));
const RefinanceLeadList = lazy(() => import("./../view/refinance/lead/component/LeadList"));
const RefinanceCommonMVMList = lazy(() => import("./../view/refinance/rule_engine_config/component/CommonMMVList"));
const RefinanceCommonMVMMUltiselectList = lazy(() => import("./../view/refinance/lead/component/CommonMMVListMultiselect"));
const RefinanceRuleEngine = lazy(() => import("./../view/refinance/rule_engine_config/component/RuleEngine"));
const RefinanceFinancerLead = lazy(() => import("./../view/refinance/rule_engine_config/component/Financer"));
const RefinanceSentToFinancerConfigListing = lazy(() => import("./../view/refinance/rule_engine_config/component/sent-to-financier-config/SentToFinancerConfigListing"));
const RefinanceSentToFinancerGroupDetail = lazy(() => import("./../view/refinance/rule_engine_config/component/sent-to-financier-config/SentToFinancerGroupDetail"));
const RefinanceExtraIncentiveConfig = lazy(() => import("./../view/refinance/rule_engine_config/component/extra_incentive_scheme/ExtraIncentiveConfiguration"));

const RefinanceAdvancePaymentLimitConfigBM = lazy(() => import("../view/refinance/rule_engine_config/component/advance-payment-limit-config/BM/PaymentConfig"));
const RefinanceAdvancePaymentLimitConfigDealer = lazy(() => import("../view/refinance/rule_engine_config/component/advance-payment-limit-config/Dealer/PaymentConfig"));
const RefinanceLoanSoftApprovalProcess = lazy(() => import("../view/refinance/loan-soft-approval/LoanSoftApprovalProcess.js"));
const RefinanceLoanSoftApprovalProcessBM = lazy(() => import("../view/refinance/loan-soft-approval/LoanSoftApprovalProcessBM.js"));

/** UBRF ROUTES IMPORT END HERE */
const BikeDashboard = lazy(() => import("./../view/bike-refinance/dashboard/Dashboard"));
const BikeLeadList = lazy(() => import("./../view/bike-refinance/lead/LeadList"));
const BikeLeadDetail = lazy(() => import("./../view/bike-refinance/lead_details/LeadDetail"));
const BikeRefinanceSentToFinancerConfigListing = lazy(() => import("./../view/bike-refinance/rule_engine_config/sent-to-financier-config/SentToFinancerConfigListing"));
const BikeRefinanceSentToFinancerGroupDetail = lazy(() => import("./../view/bike-refinance/rule_engine_config/sent-to-financier-config/SentToFinancerGroupDetail"));
const BikeRefinanceAdvancePaymentLimitConfigBM = lazy(() => import("../view/bike-refinance/rule_engine_config/advance-payment-limit-config/BM/PaymentConfig"));
const BikeRefinanceAdvancePaymentLimitConfigDealer = lazy(() => import("../view/bike-refinance/rule_engine_config/advance-payment-limit-config/Dealer/PaymentConfig"));


// financer box 
const SentToFinancerConfigListing = lazy(() => import("./../view/sent-to-financier-config/SentToFinancerConfigListing"));
const SentToFinancerGroupDetail = lazy(() => import("./../view/sent-to-financier-config/SentToFinancerGroupDetail"));
const FunnelReport = lazy(() => import("./../view/funnel-report/FunnelReport"));
const AdvancePaymentLimitConfig = lazy(() => import("../view/rule-engine/component/advance-payment-limit/PaymentConfig"));
const DealerMonthlyIncentiveConfig = lazy(() => import("./../view/rule-engine/DealerMonthlyIncentiveConfig"));

//Import IF BOX
const IFLeadList = lazy(() => import("./../view/inventory-funding/lead/LeadList"));
const IFLeadDetail = lazy(() => import("./../view/inventory-funding/lead-details/LeadDetails"));
const UpdateFinancierList = lazy(() => import("./../view/inventory-funding/update-financier-list/LeadList.js"));
const IFLoanManagement = lazy(() => import("./../view/inventory-funding/loan-management/lead/LeadList"));
const IFLoanManagementDetails = lazy(() => import("./../view/inventory-funding/loan-management/lead-detail/LeadDetails"));
const OpseeLeadList = lazy(() => import("./../view/opsee/lead-list/LeadList"));
// api data config
const ApiDataConfig = lazy(() => import("../view/api-data-config/ApiDataConfig"));
const UserTATReport = lazy(() => import("../view/user-tat-report/UserTATReport.js"));
const LoanApprovalProcess = lazy(() => import("../view/loan-approval/LoanApprovalProcess"));
const LoanApprovalProcessBM = lazy(() => import("../view/loan-approval/LoanApprovalProcessBM"));
const NotFound = lazy(() => import("../view/not-found/NotFound.js"));
const Thanks = lazy(() => import("../view/common/Thanks"));
const BPKPUpdate = lazy(() => import("../view/inventory-funding/bpkp/BPKPUpdate"));
const BPKList = lazy(() => import("../view/inventory-funding/bpkp/BPKList"));
const IFBPKList = lazy(() => import("../view/inventory-funding/bpkp/IFBPKList"));
const DealerNotification = lazy(() => import("../view/inventory-funding/DealerNotification"));
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ht: 500,
      loggedIn: false,
      authUser:{}
    };
  }

  static getDerivedStateFromProps = (nextProps, state) => {
    const updatedProps = {};
    if (
      nextProps.loggedIn !== state.loggedIn
    ) {
      updatedProps["loggedIn"] = nextProps.loggedIn;
    }
    if (
      nextProps.authUser !== state.authUser
    ) {
      updatedProps["authUser"] = nextProps.authUser;
    }
    return updatedProps
  };

  render() {
    let { loggedIn,authUser } = this.state;
    let currentPath = window.location.pathname;
    // console.log("authUser=====>",authUser)
    let isSuperAdmin = (authUser && authUser.role_id && authUser.role_id === -1) ? true : false;
    return (
      <BrowserRouter>
      <Suspense fallback={<div className="loader is-active">Loading...</div>}>
        <div>
          {loggedIn && currentPath !== '/remove-info' ? <Header /> : ""}
          <Switch>           
            <Route exact path="/" component={Login} />
            <Route path="/login" component={Login} />
            <Route path="/remove-info" component={RemoveInfo} />
            <Route path="/logout" component={Logout} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route path="/lead-list/:status/:searchtext" component={LeadList} />
            <Route path="/lead-list/:status" component={LeadList} />
            <Route path="/lead-list" component={LeadList} />
            <Route path="/lead-detail/:tab/:subtab/:loanid" component={LeadDetail} />
            {<Route path="/rule-engine" component={RuleEngine} />}
            <Route path="/role-list" component={(IS_USER_ACCESS_PAGE('role_management', 'role_management').is_access_page || isSuperAdmin) ? ManageRole : AccessDenied} />
            <Route path="/financier-list" component={IS_USER_ACCESS_PAGE('financier','financier').is_access_page ? Financier : AccessDenied } />
            <Route path="/financier-branch/:enc_code" component={FinancerBranch} />
            {<Route path="/mmv-mapping" component={IS_USER_ACCESS_PAGE('import','mmv-mapping').is_access_page ? Mmvmaster : AccessDenied} />}
            {<Route path="/deviation-config" component={IS_USER_ACCESS_PAGE('config','deviation-config').is_access_page ? DeviationConfig : AccessDenied} />}
            {!IS_TRIIVCO_LOGIN() && <Route path="/tat-report/:source/:type/:workingtype/:displaytype" component={IS_USER_ACCESS_PAGE('tat_report','tat_report').is_access_page ? TATReport : AccessDenied} />}
            {!IS_TRIIVCO_LOGIN() && <Route path="/user-tat-report" component={IS_USER_ACCESS_PAGE('user_tat_report','user_tat_report').is_access_page ? UserTATReport : AccessDenied} />}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path="/opsee-dealer-config/:user_id/:city_id/:area_id" component={true ? OpseeDealerConfig : AccessDenied} />}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path="/opsee-dealer-config" component={true ? OpseeDealerConfig : AccessDenied} />}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path="/runner-mapping-list" component={true ? RunnerMappingList : AccessDenied} />}
            {!IS_TRIIVCO_LOGIN() && <Route path="/funnel-report/" component={IS_USER_ACCESS_PAGE('funnel_report','funnel_report').is_access_page ? FunnelReport : AccessDenied} />}
            {<Route path="/dealer-monthly-incentive-config" component={ IS_USER_ACCESS_PAGE('config','deviation-config').is_access_page ? DealerMonthlyIncentiveConfig : AccessDenied } />}

            {/* UCRF ROUTES STARTS HERE  */}
            {/* <Route path="/refinance/dashboard" component={Dashboard} /> */}
            {!IS_TRIIVCO_LOGIN() && <Route path={`/refinance/lead-list`} component={RefinanceLeadList} />}
            {!IS_TRIIVCO_LOGIN() && <Route path={`/refinance/lead-detail/:loan_id_hash`} component={RefinanceLeadDetail} />}
            {!IS_TRIIVCO_LOGIN() && <Route path={`/refinance/mmv-mapping`} component={ UCRFCommonService.__isPageAccess(authUser,"ucrf_config_common_mmv_mapping") ? RefinanceCommonMVMList : AccessDenied} />}
            {!IS_TRIIVCO_LOGIN() && <Route path={`/refinance/mvm-multiValue`} component={ UCRFCommonService.__isPageAccess(authUser,"ucrf_config_common_mmv_mapping") ? RefinanceCommonMVMMUltiselectList : AccessDenied} />}
            {!IS_TRIIVCO_LOGIN() && <Route path={`/refinance/rule-engine`} component={UCRFCommonService.__isPageAccess(authUser,"","config") ? RefinanceRuleEngine : AccessDenied} />}
            {!IS_TRIIVCO_LOGIN() && <Route path={`/refinance/financer`} component={RefinanceFinancerLead} />}
            {!IS_TRIIVCO_LOGIN() && <Route path={`/refinance/email-template-config`} component={ UCRFCommonService.__isPageAccess(authUser,"ucrf_config_email_template_config") ? RefinanceSentToFinancerConfigListing : AccessDenied} />}
            {!IS_TRIIVCO_LOGIN() && <Route path={`/refinance/email-template-config-detail`} component={ UCRFCommonService.__isPageAccess(authUser,"ucrf_config_email_template_config") ? RefinanceSentToFinancerGroupDetail : AccessDenied} /> }
            {!IS_TRIIVCO_LOGIN() && <Route path={`/refinance/extra-incentive-config`} component={ UCRFCommonService.__isPageAccess(authUser,"ucrf_config_extra_incentive") ? RefinanceExtraIncentiveConfig : AccessDenied} />}
            {!IS_TRIIVCO_LOGIN() && <Route path={`/refinance/advance-payment-limit-config-bm`}  component={ UCRFCommonService.__isPageAccess(authUser,"ucrf_config_advance_payment_limit") ? RefinanceAdvancePaymentLimitConfigBM : AccessDenied} />}
            {!IS_TRIIVCO_LOGIN() && <Route path={`/refinance/advance-payment-limit-config-dealer`}  component={ UCRFCommonService.__isPageAccess(authUser,"ucrf_config_advance_payment_limit") ? RefinanceAdvancePaymentLimitConfigDealer : AccessDenied} />}
            {!IS_TRIIVCO_LOGIN() && <Route path={`/refinance/loan-soft-approval-process/:url_token/:lead_id_hash/:lead_application_id_hash/:type`} component={RefinanceLoanSoftApprovalProcess}/>}
            {!IS_TRIIVCO_LOGIN() && <Route path={`/refinance/bm-loan-soft-approval-process/:url_token/:lead_id_hash/:lead_application_id_hash/:type`} component={RefinanceLoanSoftApprovalProcessBM}/>}
            
            {/* UBRF ROUTES STARTS HERE  */}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN()  && <Route path="/bike-refinance" exact component={BikeDashboard} />}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path="/bike-refinance/dashboard" component={BikeDashboard} />}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/bike-refinance/lead-list`} component={BikeLeadList} />}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/bike-refinance/lead-detail/:loan_id_hash`} component={BikeLeadDetail} />}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/bike-refinance/rule-engine`} component={UBRFCommonService.__isPageAccess(authUser,"","config") ? RefinanceRuleEngine : AccessDenied} />}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/bike-refinance/email-template-config`} component={UBRFCommonService.__isPageAccess(authUser,"ubrf_config_email_template_config") ? BikeRefinanceSentToFinancerConfigListing : AccessDenied} />}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/bike-refinance/email-template-config-detail`} component={UBRFCommonService.__isPageAccess(authUser,"ubrf_config_email_template_config") ? BikeRefinanceSentToFinancerGroupDetail : AccessDenied} />}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/bike-refinance/advance-payment-limit-config-bm`}  component={ UBRFCommonService.__isPageAccess(authUser,"ubrf_config_advance_payment_limit") ? BikeRefinanceAdvancePaymentLimitConfigBM : AccessDenied} />}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/bike-refinance/advance-payment-limit-config-dealer`}  component={ UBRFCommonService.__isPageAccess(authUser,"ubrf_config_advance_payment_limit") ? BikeRefinanceAdvancePaymentLimitConfigDealer : AccessDenied} />}

            {<Route path={`/email-template-config`} component={IS_USER_ACCESS_PAGE('email_template_config','email_template_config').is_access_page ? SentToFinancerConfigListing : AccessDenied} />}
            {<Route path={`/email-template-config-detail`} component={IS_USER_ACCESS_PAGE('email_template_config','email_template_config').is_access_page ? SentToFinancerGroupDetail : AccessDenied} />            }
            {!IS_FAVOURITE_LOGIN() && <Route path={`/advance-payment-limit-config-bm`} component={IS_USER_ACCESS_PAGE('advance_payment_limit','advance_payment_limit').is_access_page ? AdvancePaymentLimitConfig : AccessDenied} />  }
            {!IS_FAVOURITE_LOGIN() && <Route path={`/advance-payment-limit-config-dealer`}  component={IS_USER_ACCESS_PAGE('advance_payment_limit','advance_payment_limit').is_access_page ? AdvancePaymentLimitConfig : AccessDenied} />   }

            {/* Inventory FUnding ROUTES STARTS HERE  */}
            {/* <Route path={`/inventory-funding/lead-list`} component={IFLeadList} /> */}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/inventory-funding/lead-list/:status_id`} component={IFLeadList} />}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/inventory-funding/lead-list`} component={IFLeadList} />}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/inventory-funding/lead-details/:loan_id`} component={IFLeadDetail} />}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/inventory-funding/update-financier`} component={UpdateFinancierList} />}

            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/inventory-funding/loan-management/lead-details/:loan_id`} component={IFLoanManagementDetails} />}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/inventory-funding/loan-management/:dealer_dashboard`} component={IFLoanManagement} />}
            {!IS_FAVOURITE_LOGIN() && <Route path={`/inventory-funding/loan-management`} component={IFLoanManagement} />}

            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/loan-approval-process/:token/:lead_id/:type`} component={LoanApprovalProcess}/>}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/bm-loan-approval-process/:token/:lead_id/:type`} component={LoanApprovalProcessBM}/>}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/api-data-config`} component={ApiDataConfig}/>}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/bpkp-update/:token/:lead_id`} component={BPKPUpdate}/>}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/bpkp-update/:token`} component={BPKPUpdate}/> }
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/bpkp-list/:token`} component={BPKList}/>}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/inventory-funding/bpkp-list`} component={IFBPKList}/>  }
            {!IS_FAVOURITE_LOGIN() && <Route path={`/thanks`} component={Thanks} />}
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/inventory-funding/dealer-notifications`} component={DealerNotification}/>  }
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/inventory-funding/opsee/bpkb-collection`} component={OpseeLeadList}/>  }
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/inventory-funding/opsee/bpkb-distribution`} component={OpseeLeadList}/>  }
            {!IS_FAVOURITE_LOGIN() && !IS_TRIIVCO_LOGIN() && <Route path={`/inventory-funding/opsee/auditor`} component={OpseeLeadList}/>  }
            <Route component={NotFound} />
          </Switch>
          {loggedIn === true ? <Footer /> : ""}
        </div>
        </Suspense>
      </BrowserRouter>
    );
  }
}
const mapStateToProps = ({ user }) => {
  return {
    loggedIn: user.loggedIn,
    authUser:user.authUser
  };
};
export default connect(mapStateToProps, null)(App);

