import secureStorage from "./encrypt";
import _ from "lodash";
import ReactGA from 'react-ga4';
export const API_URL = {
  GETWAY_API: process.env.REACT_APP_GETWAY_API,
  COMMON_GATEWAY_API: process.env.REACT_APP_GETWAY_API,
  SFA_HOST: "http://int-sfa-stage.oto.com/",
  GETWAY_BASE_PATH: process.env.REACT_APP_GETWAY_BASE_PATH,
  BASE_PATH_PREFIX: "loan",
};

export const SETTING = {
  CRYPTO_KEY: "U*oDwrIU!B#QX37%#24",
  RESEND_OTP_TIME_IN_SECOND: 30,
};

export const RULE_ENGILE = {
  BOOSTER_SCHEME_MAX_VALIDATION: 25000000,
  DEVIATION_AMOUNT_VALIDATION: -10000000
};

export const SITE_CONTENTS = {
  DEALER_SUPPORT_EMAIL: "dealersupport@gaadi.com",
  DEALER_SUPPORT_PHONE: "+919069138803",
  SITE_NAME: "Gaadi Web Pvt. Ltd.",
  SITE_ADDRESS:
    "Floor 2nd. Plot No. - 48, Opposite EPFO Building, Behind Hotel Taj Vivanta, Sector - 44, Gurgaon- 122003, Haryana, India"
};

export const MOBILE_VLIDATION_RULE = /^[8]\d{8,11}$/;

export const MARGIN_FOR_OTO_MAX_VALIDATION = 1000000;

export const NAME_VALIDATION = /^[a-zA-Z \.]+$/;

export const ALPHA_NUMERIC_VALIDATION = /^[a-zA-Z0-9 ]+$/;

export const ALPHA_NUMERIC_WITH_DOT_VALIDATION = /^[a-zA-Z0-9 \.]+$/;

export const NUMERIC_VALIDATION = /^\d+$/;

export const ACCOUNT_NO_VALIDATION = /^\d{10,16}$/;

export const SPOUSE_OFFICE_ADDRESS_VALIDATION = /^[a-zA-Z0-9@%_&*$#!~()<>?+:,;=^?|'-<>.{} ]{1,70}$/;

export const SPOUSE_BUSINESS_VALIDATION = /^[a-zA-Z0-9@%_&*$#!~()<>?+:,;=^?|'-<>.{} ]{1,20}$/;

export const EMERGENCY_CONTACT_NAME_VALIDATION = /^[a-zA-Z0-9@%_&*$#!~()<>?+:,;=^?|'-<>.{} ]{1,50}$/;

export const EMERGENCY_CONTACT_ADDRESS_VALIDATION = /^[a-zA-Z0-9@%_&*$#!~()<>?+:,;=^?|'-<>.{} ]{1,70}$/;

// export const MOBILE_VALIDATION_RULE = /^\+?\d{8,11}$/;

export const EMAIL_VALIDATION_RULE = /^[^@]+@[^@]+\.[^@]+$/;

export const IS_FAVOURITE_LOGIN = () => {	
	return API_URL.GETWAY_BASE_PATH === 'favourite_id';
};
export const IS_TRIIVCO_LOGIN = () => {	
	return API_URL.GETWAY_BASE_PATH === 'triv-id';
};

export const SHOW_TEXT_LABEL = () => {
	if (API_URL.GETWAY_BASE_PATH === 'favourite_id') {
		return {
			platinum: "Platinum",
			dsa: "DSA MO"
		}
	}else{
		return {
			platinum: "Alpha",
			dsa: "DSA"
		}
	}
};

export const SHOW_USER_ROLE_NAME = () => {
	if (API_URL.GETWAY_BASE_PATH === 'favourite_id') {
		return {
			AMF: "AMF",
			BM: "MM",
			AM: "RM",
			ZM: "GM",
			RBH: "RBH",
			AGENT_BRO: "FA/PA/MO",
		}
	}else{
		return {
			BM: "BM",
			ZM: "ZM",
			AM: "AM",
			RBH: "RBH",
			AGENT_BRO: "Alpha Agent/Agent/BRO",
		}
	}
};

export const SHOW_USER_ROLE_NAME_UCF = () => {
	if (API_URL.GETWAY_BASE_PATH === 'favourite_id') {
		return {
			BM: "MM",
			ZM: "GM",
			AM: "RM",
			RBH: "RBH",
			AGENT: "FA",
			BRO: "MO",
			ARO:"ARO",
			ARCO:"ARCO",
			ALPHA_AGENT:"Platinum Agent",
		}
	}else{
		return {
			BM: "BM",
			ZM: "ZM",
			AM: "AM",
			RBH: "RBH",
			AGENT: "Agent",
			BRO: "BRO",
			ARO:"ARO",
			ARCO:"ARCO",
			ALPHA_AGENT:"ALPHA AGENT",
		}
	}
};

export const IS_USER_ACCESS_PAGE = (controller, action) => {
	let isUserHasRights = false, isEditAccess = false;
	let loginData = secureStorage.getItem("loginUserInfo");
	let isSuperAdmin = loginData && loginData.data && loginData.data.user_data && loginData.data.user_data.role_id === -1;
	if (isSuperAdmin) {
		isUserHasRights = true;
		isEditAccess = true;
	} else {
		const userAccessFeatures = secureStorage.getItem("userAccess");
		if (userAccessFeatures && userAccessFeatures.length) {
			let isKeyExist = _.findKey(userAccessFeatures, function (v) {
				if (controller && v.controller && action && v.action) {
					return (
						v.controller.toLowerCase() === controller.toLowerCase() && 
						v.action.toLowerCase() === action.toLowerCase()
					);
				}
			});
			isUserHasRights = isKeyExist ? true : false;
			isEditAccess = isKeyExist ? ((Number(userAccessFeatures[isKeyExist].access_type) === 1) ? true : false) : false
		}
	}
	if(controller === 'UpdateLead' && loginData && loginData.data && loginData.data.user_data && loginData.data.user_data.user_id === 16){
		isEditAccess = true
		isUserHasRights = true 
	} 

	if(controller === 'UpdateLead' && loginData && loginData.data && loginData.data.user_data && loginData.data.user_data.user_id === 16){
		isEditAccess = true
		isUserHasRights = true 
	} 

	return {
		is_access_page: isUserHasRights,
		is_edit_access: isEditAccess
	};
};


export const CHECK_ASSIGN_USER = (assign_to) => {
	let loginData = secureStorage.getItem("loginUserInfo");
	let isSuperAdmin = loginData && loginData.data && loginData.data.user_data && (loginData.data.user_data.role_id === -1 || loginData.data.user_data.role_id===2 || loginData.data.user_data.assigned_role_ids.includes("2"));
	if(isSuperAdmin) return false;
	return !(assign_to===loginData?.data?.user_data?.user_id);
};

export const FINANCIER_ARRAY_FOR_RE_PDF_DOWNLOAD = [2, 3, 6, 12, 13, 14, 15, 16, 18, 23, 25, 26, 27 ,31 ,110, 111 , 105, 114, 118, 120, 125];

export const USER_ROLE = () => {
	if (API_URL.GETWAY_BASE_PATH === 'favourite_id') {
		return [
			{key:"BM",value:"MM"},
			{key:"AM",value:"RM"},
			{key:"ZM",value:"GM"},
			{key:"RBH",value:"RBH"}
		]
	}else{
		return [
			{key:"BM",value:"BM"},
			{key:"AM",value:"AM"},
			{key:"ZM",value:"ZM"},
			{key:"RBH",value:"RBH"}
		]
	}
};

export const FINANCIER_PRODUCT_TYPE_OPTIONS = [
	{ label: "UCF", value: "finance" },
	{ label: "UCRF", value: "refinance" },
	// { label: "UBRF", value: "UBRF" },
	// { label: "INSURANCE", value: "INSURANCE" },
];
//export const TAX_VALUE = 1.09;///// 1.08

//export const FINANCIER_ARRAY_FOR_TAX_BOOSTER = [6,14,30,18,23,27,13,35,32,15,19,3,22,16,25,26,31,110,105,111,114,118,120];

//export const RBH_ID_FOR_TAX_BOOSTER = 891;
export const DATE_FIELD_NAME={
	deviation_approved_date:"Deviation Approved Date",
	payment_request_received_date:"Payment Request Received Date"
}

export const WHATSAPP_URL = "https://web.whatsapp.com/send?text="

export const ADIRA_INTEGRATION_FINANCIER = [13, 35];
export const ADIRA_INTEGRATION_BRANCHES = [299, 300, 302, 304, 306, 324, 325, 489, 3504, 608, 316, 319, 320, 506];

export const ADIRA_MARITAL_STATUS = {
	"1": [
		{ id: "1", label: "Single", value: "Unmarried" },
		{ id: "2", label: "Married", value: "Married" },
		{ id: "3", label: "Widowed without Children", value: "widowed_without_children" },
		{ id: "4", label: "Widowed with Children", value: "widowed_with_children" },
	],
	"2": [
		{ id: "1", label: "SINGLE", value: "Unmarried" },
		{ id: "2", label: "KAWIN", value: "Married" },
		{ id: "3", label: "DUDA/JANDA TANPA ANAK", value: "widowed_without_children" },
		{ id: "4", label: "DUDA/JANDA DGN ANAK", value: "widowed_with_children" },
	]
};

export const IS_ADIRA_FINANCIER = (lead_data) => {
    if(!lead_data) return false;
    let financier_ids= lead_data?.financier_list?.filter((sf)=>sf.is_loan_applied==="yes")?.map((result)=> result.financier_id) || [];
    let branch_ids= lead_data?.financier_list?.filter((sf)=>sf.is_loan_applied==="yes" && ADIRA_INTEGRATION_FINANCIER.includes(sf.financier_id))?.map((result)=> result.branch_id) || [];
    let already_po_arr= lead_data?.financier_list?.filter((sf)=>sf.is_loan_applied==="yes" && ADIRA_INTEGRATION_FINANCIER.includes(sf.financier_id))?.map((result)=> result.already_po) || [];
    let has_rule_engine_arr= lead_data?.leadLoanDetails?.filter((sf)=> ADIRA_INTEGRATION_FINANCIER.includes(sf.quote_financier_id))?.map((result)=> result.has_rule_engine) || [];

    if (!financier_ids.length || !branch_ids.length || !already_po_arr.length || !has_rule_engine_arr.length) {
        return null;
    }
    if (
      ADIRA_INTEGRATION_FINANCIER?.filter(fn=>financier_ids.includes(fn))?.length &&
      ADIRA_INTEGRATION_BRANCHES?.filter(fn=>branch_ids.includes(fn))?.length &&
      (already_po_arr.includes("No") || already_po_arr.includes("NO") || already_po_arr.includes("NA")) &&
      (lead_data.lead_bucket_name || has_rule_engine_arr.includes("1"))
    ) {
      return lead_data?.financier_list?.filter((sf)=>sf.is_loan_applied==="yes" && ADIRA_INTEGRATION_FINANCIER.includes(sf.financier_id))?.[0] || {};
    }
	return false;
}

export const ADMIN_ROLE_ID = 2;

export const ROLE_MANAGEMENT_IDS = {
	ADMIN_ROLE_ID: 2,
	LEAD_MANAGEMENT_ROLE_ID: 3,
	DO_MAKER_ROLE_ID: 4,
	DO_CHECKER_ROLE_ID: 5,
	BACKUP_TEAM_ROLE_ID: 15
};

export const ROLE_MANAGEMENT_SUB_STATUSES = {
	LEAD_MANAGEMENT_ROLE_SUB_STATUS: [1, 2, 3, 4, 5, 6, 7, 24, 25, 36, 8, 9, 26, 27, 50, 59],
	DO_MAKER_ROLE_SUB_STATUS: [10],
	DO_CHECKER_ROLE_SUB_STATUS: [48],
	BACKUP_TEAM_ROLE_SUB_STATUS: [38, 56, 37, 51, 52, 11, 12, 14, 15, 16, 49, 29],
	OPEN_CASES_SUB_STATUS: [7, 26, 8, 9, 27]
};

export const CHECK_RULE_ENGINE_VIEW_ACCESS = () => {
	let haveConfigAccess = false, haveImportAccess = false, haveBoosterAccess = false, haveReassignAccess = false;
	if (
		IS_USER_ACCESS_PAGE("calculator", "core-config").is_access_page ||
		IS_USER_ACCESS_PAGE("config", "deviation-config").is_access_page ||
		IS_USER_ACCESS_PAGE("import", "mmv-mapping").is_access_page ||
		IS_USER_ACCESS_PAGE("email_template_config", "email_template_config").is_access_page || 
		IS_USER_ACCESS_PAGE("payment_request_charge", "payment_request_charge").is_access_page ||
		IS_USER_ACCESS_PAGE("advance_payment_limit", "advance_payment_limit").is_access_page || 
		IS_USER_ACCESS_PAGE("tat_breach_config", "tat_breach_config").is_access_page
	) {
		haveConfigAccess = true;
	}
	if (
		IS_USER_ACCESS_PAGE("import_mrp_table", "import_mrp_table").is_access_page ||
		IS_USER_ACCESS_PAGE("import_mrp_bcamf", "import_mrp_bcamf").is_access_page ||
		IS_USER_ACCESS_PAGE("import_mrp_buana", "import_mrp_buana").is_access_page ||
		IS_USER_ACCESS_PAGE("import_model_code", "import_model_code").is_access_page ||
		IS_USER_ACCESS_PAGE("import_total_loan_amount","import_total_loan_amount").is_access_page ||
		IS_USER_ACCESS_PAGE("import_brand_type", "import_brand_type").is_access_page ||
		IS_USER_ACCESS_PAGE("import_refund_amount", "import_refund_amount").is_access_page
	  ) {
		haveImportAccess = true;
	  }
	  if (
		IS_USER_ACCESS_PAGE("import", "update-lead-dealer").is_access_page ||
		IS_USER_ACCESS_PAGE("import", "update-lead-branch").is_access_page
	  ) {
		haveReassignAccess = true;
	  }
	  if (
		IS_USER_ACCESS_PAGE("boosterRanges", "booster-ranges").is_access_page ||
		IS_USER_ACCESS_PAGE("boosterAmount", "booster-amounts").is_access_page
	  ) {
		haveBoosterAccess = true;
	  }
	  return {
		"config": haveConfigAccess,
		"import": haveImportAccess,
		"reassign": haveReassignAccess,
		"booster": haveBoosterAccess,
		"no-access": !haveConfigAccess && !haveImportAccess && !haveReassignAccess && !haveBoosterAccess
	  }
}

export const IS_STRING_TO_OBJECT = (str) => {
	try {
		return JSON.parse(str);
	} catch (e) {
		return {};
	}
}

export const IS_MPMF_FINANCIER = (lead_data) => {
	if (!lead_data) return false;
	let { transaction_id } = lead_data;
	let financier_ids=lead_data?.financier_list?.filter((sf)=>sf.is_loan_applied==="yes")?.map((result)=> result.financier_id) || [];
	let already_po= lead_data?.financier_list?.filter((sf)=>sf.is_loan_applied==="yes" && sf.financier_id === 15)?.[0]?.already_po || "";
	let has_rule_engine= lead_data?.leadLoanDetails?.filter((sf)=>sf.quote_financier_id === 15)?.[0]?.has_rule_engine || "";
	if (!financier_ids.length || !already_po || !has_rule_engine || !transaction_id) return false;
	if (financier_ids.includes(15) && ["No", "NO", "NA"].includes(already_po) && has_rule_engine === "1") return true;
	else return false;
}

export const BM_ADVANCE_PAYMENT_LIMIT_DEFAULT_SCHEME_ID = 1;
export const DEALER_ADVANCE_PAYMENT_LIMIT_DEFAULT_SCHEME_ID = 2;
export const CURRENCY_SYMBOL= "Rp. ";
export const CURRENCY_SEPARATOR= ".";

export const HO_REFUND_FINANCIER = [13, 32, 35];

export const INTEREST_RATE_CONFIG = {
	min_per: 1,
	max_per: 20
}
export const GA_TRACKING_ID = "G-4K1EG57EP2";
export const TRIGGER_GA_EVENT = (category, label) => {
	ReactGA.event({
		category: category,
		action: `click_${label}`,
		label: label
	})
}
export const AUTH_KEY="lnaprv-3567-8765-4121-appro-b34b-mrp-xywktree234";

export const DOC_BUTTON_NAME = {
	22: "Customer KTP",
	23: "Guarantor/Spouse",
	4: "Company NPWP"
}

export const APPLICATION_MAPPING_FINANCIER = [3,13,15]

export const DIF_BOOSTER_MAXIMUM_LIMIT = 3675000;
export const DIF_BOOSTER_MINIMUM_LIMIT = 10000;
export const BRANCH_REGION_MAPPING = {
	"scheme-1": 0,
	"region-1": 1,
	"region-2": 2,
	"region-3": 3,
	"region-4": 4
}

export const SLAB_DATA_LIMIT = 5;

export const MRP_LIMIT = 300000000

export const WHT_PER_CONFIG = {
	min_per: 0,
	max_per: 100
}

export const REQUIRED_LATITUDE_LONGITUDE_ROLE_IDS = [46,47,48,51]
export const OPSEE_DOC_IDS = {
	'runner_holding_bpkb': 2,
	'bpkb_personal_data': 3,
	'bpkb_car_data': 4,
	'bpkb_detailed_documents': 5,
	'upload_handover_document': 10,
	'upload_handover_proof': 11,
}
export const API_KEY = "d77bc90f-6824-47da-94c2-2930de73daad";